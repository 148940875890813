import $axios from "@/service/service.util.js";

//获取用户列表
const getAdminYHList = (data) => {
    return $axios({
      url: process.env.VUE_APP_BASE_API + '/iot/user/list',
      method: 'post',
      data
    })
  }
  //保存用户
const saveYH = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/user/add',
    method: 'post',
    data
  })
}

 //修改用户
 const updateYH = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/user/update',
    method: 'post',
    data
  })
}

//修改密码
const updateYHPwd = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/user/updatePassword',
    method: 'post',
    data
  })
}

//删除用户
const delYH = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/user/logicDelete',
    method: 'post',
    data
  })
}


  export default {
    saveYH,
    updateYH,
    delYH,
    getAdminYHList,
    updateYHPwd
  }