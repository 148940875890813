<template>
<el-scrollbar class="app-sideBar" :style="{'width':sidebar.width}" wrap-class="scrollbar-wrapper">
    <div class="title">
        <img src="@/assets/logo.png" alt="">
        <h1 style="font-size:18px; margin-top:10px;" v-if="isShowTitle">鼎通NB-IoT设备管理平台</h1>
    </div>
    <!-- <el-menu mode="vertical" :show-timeout="200" :default-active="$route.path" router background-color="#0055b1" text-color="#ffffff" active-text-color="#ffffff">
        <sidebar-item v-for="route in constantRouterMapPower" :key="route.path" :item="route" :base-path="route.path"></sidebar-item>
    </el-menu> -->
    <el-menu mode="vertical" :show-timeout="200" :default-active="$route.path" router class="elmenu" text-color="#ffffff" active-text-color="#ffffff">
        <sidebar-item v-for="route in constantRouterMapPower" :key="route.path" :item="route" :base-path="route.path"></sidebar-item>
    </el-menu>
</el-scrollbar>
</template>

<script>
import {
    constantRouterMap
} from "@/router/index.js";
import SidebarItem from "./components/SidebarItem.vue";
import {
    Message
} from "element-ui"
import {
    mapGetters
} from 'vuex'
import {
    setTimeout
} from 'timers';
export default {
    components: {
        SidebarItem
    },
    data() {
        return {
            isShowTitle: true,
            constantRouterMapPower: null
        }
    },
    mounted() {
        this.filterPower1();
        // this.noAuth()
    },
    computed: {
        ...mapGetters(['isCollapse', 'sidebar'])
    },
    methods: {
        //权限过滤
        filterPower() {
            this.constantRouterMapPower = constantRouterMap;
            const menuPower = sessionStorage.getItem('accountInfo') ? JSON.parse(sessionStorage.getItem('accountInfo')).menuCode : []
            console.log(constantRouterMap)
            this.constantRouterMapPower.map(item => {
                if (menuPower.every(n => n !== item.meta.id)) {
                    item.hidden = true
                    item.alwaysShow = false
                    if (item.children) {
                        item.children.map(child => {
                            child.hidden = true
                        })
                    }
                } else {
                    item.hidden = false
                    item.alwaysShow = true
                    if (item.children) {
                        item.children.map(child => {
                            child.hidden = false
                        })
                    }
                }
            })
        },
        //权限过滤
        filterPower1() {
            this.constantRouterMapPower = constantRouterMap
            const accountInfo = sessionStorage.getItem('accountInfo') ? JSON.parse(sessionStorage.getItem('accountInfo')) : {}
            console.log(accountInfo)
            if (accountInfo && accountInfo.operatorType === 2) {
                this.constantRouterMapPower.map(item => {
                    if (item.meta.id === '0001') {
                        item.hidden = false
                        item.alwaysShow = true
                        if (item.children) {
                            item.children.map(child => {
                                if (child.meta.id == '00012') {
                                    child.hidden = false
                                } else {
                                    child.hidden = true
                                }
                            })
                        }
                    } else {
                        console.log(item.meta)
                        item.hidden = true
                        item.alwaysShow = false
                        if (item.children) {
                            item.children.map(child => {
                                child.hidden = true
                            })
                        }
                    }
                })
            } else {
                this.constantRouterMapPower.map(item => {
                    item.hidden = false
                    item.alwaysShow = true
                    if (item.children) {
                        item.children.map(child => {
                              child.hidden = false
                        })
                    }
                })
            }

        },
        noAuth() {
            this.constantRouterMapPower = constantRouterMap;
            console.log(constantRouterMap)
            this.constantRouterMapPower.map(item => {
                item.hidden = false
                item.alwaysShow = true
                if (item.children) {
                    item.children.map(child => {
                        child.hidden = false
                    })
                }
            })
        },
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        }
    },
    watch: {
        sidebar: {
            handler(newV, oldV) {
                if (newV.width == "210px") {
                    setTimeout(() => {
                        this.isShowTitle = true
                    }, 300)
                } else {
                    this.isShowTitle = false
                }
            },
            deep: true
        }
    }
}
</script>
