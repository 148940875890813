import Vue from 'vue'
import Router from 'vue-router'
import Layout from "@/views/Layout/Layout"// 公共视图层
import watermark from '../utils/watermark'
Vue.use(Router)

/**
 * hidden: true  如果 'hidden' 为 true 则不会在边栏中显示（默认值为false）
 * alwaysShow: true 如果为true 表示显示一级展示
 *
 * meta: {
 *     title: '首页'     子菜单和面包屑中显示的名称
 *     icon: 'el-xxx'    图标字
 * }
 */

export const constantRouterMap = [
    {
        path: '/login',
        hidden: true,
        name: 'signIn',
        meta: {
            status: 'login',
            id: '1',
            title:'鼎通NB-IoT设备管理平台'
        },
        component: () => import('@/views/Login/SignIn.vue')
    },
    {
        path: '/binddevice',
        hidden: true,
        name: 'bindDevice',
        meta: {
            status: 'binddevice',
            id: '100',
            title:'设置余额报警提醒'
        },
        component: () => import('@/views/H5/bindDevice.vue')
    },
    {
        path: '/',
        name: 'sbgl',
        hidden: true,
        component: Layout,
        alwaysShow: true,
        redirect: '/index',
        meta: {
            title: '设备管理',
            icon: require('@/assets/icon/menu/meter.png'),
            code: 'sbgl',
            id: '0001'
        },
        children: [
            {
                path: 'index',
                name: 'index',
                hidden: true,
                component: () => import('@/views/SBGL/Device/sbIndex.vue'),
                meta: {
                    title: '设备列表',
                    code: 'sbgl1',
                    id: '00011'
                }
            },
            {
                path: 'jwxlist',
                name: 'jwxlist',
                hidden: true,
                component: () => import('@/views/SBGL/Device/components/jwx/sbIndex.vue'),
                meta: {
                    title: 'JWX设备列表',
                    code: 'sbgl2',
                    id: '00012'
                }
            },
            {
                path: 'jzqlist',
                name: 'jzqlist',
                hidden: true,
                component: () => import('@/views/SBGL/Device/components/jzq/sbIndex.vue'),
                meta: {
                    title: '集中器管理',
                    code: 'sbgl3',
                    id: '00013'
                }
            }
        ]
    },
    {
        path: '/sblx',
        name: 'sblx',
        hidden: true,
        component: Layout,
        alwaysShow: true,
        redirect: '/sblx/sblxgl',
        meta: {
            title: '设备类型管理',
            icon: require('@/assets/icon/menu/xl.png'),
            code: 'bm',
            id: '0002'
        },
        children: [
            {
                path: 'sblxgl',
                name: 'sblxgl',
                hidden: true,
                component: () => import('@/views/SBLXGL/sblxIndex.vue'),
                meta: {
                    title: '设备类型列表',
                    code: 'sblxlist',
                    id: '00021'
                }
            }
        ]
    },
    {
        path: '/ssgl',
        name: 'ssgl',
        hidden: true,
        component: Layout,
        alwaysShow: true,
        redirect: '/ssgl/shuisi',
        meta: {
            title: '水司管理',
            icon: require('@/assets/icon/menu/company.png'),
            code: 'ssgl',
            id: '0003'
        },
        children: [
            {
                path: 'shuisi',
                name: 'shuisi',
                hidden: true,
                component: () => import('@/views/SSGL/ssIndex.vue'),
                meta: {
                    title: '水司列表',
                    code: 'ssgl1',
                    id: '00031'
                }
            }
        ]
    },
    {
        path: '/pcgl',
        name: 'pcgl',
        hidden: true,
        component: Layout,
        alwaysShow: true,
        redirect: '/pcgl/pici',
        meta: {
            title: '批次管理',
            icon: require('@/assets/icon/menu/pc.png'),
            code: 'pcgl',
            id: '0004'
        },
        children: [
            {
                path: 'pici',
                name: 'pici',
                hidden: true,
                component: () => import('@/views/PCGL/pcIndex.vue'),
                meta: {
                    title: '批次列表',
                    code: 'pcgl1',
                    id: '00041'
                }
            }
        ]
    },
    {
        path: '/yhgl',
        name: 'yhgl',
        hidden: true,
        component: Layout,
        alwaysShow: true,
        redirect: '/yhgl/yonghu',
        meta: {
            title: '用户管理',
            icon: require('@/assets/icon/menu/member.png'),
            code: 'pcgl',
            id: '0005'
        },
        children: [
            {
                path: 'yonghu',
                name: 'yonghu',
                hidden: true,
                component: () => import('@/views/YHGL/yhIndex.vue'),
                meta: {
                    title: '用户列表',
                    code: 'rygl1',
                    id: '00051'
                }
            }
        ]
    },
    // {
    //     path: '/csgl',
    //     name: 'csgl',
    //     hidden: true,
    //     component: Layout,
    //     alwaysShow: true,
    //     redirect: '/csgl/canshu',
    //     meta: {
    //         title: '参数管理',
    //         icon: require('@/assets/icon/menu/cs.png'),
    //         code: 'csgl',
    //         id: '0002'
    //     },
    //     children: [
    //         {
    //             path: 'canshu',
    //             name: 'canshu',
    //             hidden: true,
    //             component: () => import('@/views/CSGL/csIndex.vue'),
    //             meta: {
    //                 title: '参数列表',
    //                 code: 'csgl1',
    //                 id: '00021'
    //             }
    //         }
    //     ]
    // },
    // {
    //     path: '/jkgl',
    //     name: 'jkgl',
    //     hidden: true,
    //     component: Layout,
    //     alwaysShow: true,
    //     redirect: '/jkgl/ssjk',
    //     meta: {
    //         title: '第三方接口管理',
    //         icon: require('@/assets/icon/menu/api.png'),
    //         code: 'jkgl',
    //         id: '0003'
    //     },
    //     children: [
    //         {
    //             path: 'ssjk',
    //             name: 'ssjk',
    //             hidden: true,
    //             component: () => import('@/views/JKGL/jkIndex.vue'),
    //             meta: {
    //                 title: '水司接口管理',
    //                 code: 'ssjk1',
    //                 id: '00031'
    //             }
    //         }
    //     ]
    // },
    // {
    //     path: '/zhgl',
    //     name: 'zhgl',
    //     hidden: true,
    //     component: Layout,
    //     alwaysShow: true,
    //     redirect: '/zhgl/ptzhgl',
    //     meta: {
    //         title: '账号管理',
    //         icon: require('@/assets/icon/menu/account.png'),
    //         code: 'hygl',
    //         id: '0004'
    //     },
    //     children: [
    //         {
    //             path: 'ptzhgl',
    //             name: 'ptzhgl',
    //             hidden: true,
    //             component: () => import('@/views/YHGL/yhIndex.vue'),
    //             meta: {
    //                 title: '平台登录账号管理',
    //                 code: 'hyxl',
    //                 id: '00041'
    //             }
    //         }
    //     ]
    // },
    // {
    //     path: '/xlgl',
    //     name: 'xlgl',
    //     hidden: true,
    //     component: Layout,
    //     alwaysShow: true,
    //     redirect: '/xlgl/xilieguanli',
    //     meta: {
    //         title: '日志管理',
    //         icon: require('@/assets/icon/menu/log.png'),
    //         code: 'xlgl',
    //         id: '0005'
    //     },
    //     children: [
    //         // {
    //         //     path: 'xilieguanli',
    //         //     name: 'xilieguanli',
    //         //     hidden: true,
    //         //     component: () => import('@/views/XLGL/xlIndex.vue'),
    //         //     meta: {
    //         //         title: '登录管理',
    //         //         code: 'xlgl1',
    //         //         id: '00051'
    //         //     }
    //         // },
    //         {
    //             path: 'xilieguanli1',
    //             name: 'xilieguanli1',
    //             hidden: true,
    //             component: () => import('@/views/XLGL/xlIndex.vue'),
    //             meta: {
    //                 title: '指令日志',
    //                 code: 'xlgl1',
    //                 id: '00051'
    //             }
    //         }
    //     ]
    // },
    // ,
    // {
    //     path: '/zbgl',
    //     name: 'zbgl',
    //     hidden: true,
    //     component: Layout,
    //     alwaysShow: true,
    //     redirect: '/zbgl/zhiboguanli',
    //     meta: {
    //         title: '直播管理',
    //         icon: require('@/assets/icon/menu/zb.png'),
    //         code: 'zbgl',
    //         id: '0006'
    //     },
    //     children: [
    //         {
    //             path: 'zhiboguanli',
    //             name: 'zhiboguanli',
    //             hidden: true,
    //             component: () => import('@/views/ZBGL/zbIndex.vue'),
    //             meta: {
    //                 title: '直播管理',
    //                 code: 'zbgl1',
    //                 id: '00061'
    //             }
    //         }
    //     ]
    // },
    // {
    //     path: '/kzgl',
    //     name: 'kzgl',
    //     hidden: true,
    //     component: Layout,
    //     alwaysShow: true,
    //     redirect: '/kzgl/kaozhengguanli',
    //     meta: {
    //         title: '考证管理',
    //         icon: require('@/assets/icon/menu/kz.png'),
    //         code: 'kzgl',
    //         id: '0007'
    //     },
    //     children: [
    //         {
    //             path: 'kaozhengguanli',
    //             name: 'kaozhengguanli',
    //             hidden: true,
    //             component: () => import('@/views/KZGL/kzIndex.vue'),
    //             meta: {
    //                 title: '考证管理',
    //                 code: 'kzgl1',
    //                 id: '00071'
    //             }
    //         }
    //     ]
    // },
    // {
    //     path: '/tjfx',
    //     name: 'tjfx',
    //     hidden: true,
    //     component: Layout,
    //     alwaysShow: true,
    //     redirect: '/tjfx/xstj',
    //     meta: {
    //         title: '统计分析',
    //         icon: require('@/assets/icon/menu/tj.png'),
    //         code: 'tjfx',
    //         id: '0008'
    //     },
    //     children: [
    //         {
    //             path: 'xstj',
    //             name: 'xiaoshoutongji',
    //             hidden: true,
    //             component: () => import('@/views/TJFX/components/xstjList.vue'),
    //             meta: {
    //                 title: '销售统计',
    //                 code: 'xstj',
    //                 id: '00081'
    //             }
    //         },
    //         {
    //             path: 'bftj',
    //             name: 'bofangtongji',
    //             hidden: true,
    //             component: () => import('@/views/TJFX/components/bftjList.vue'),
    //             meta: {
    //                 title: '播放统计',
    //                 code: 'bftj',
    //                 id: '00082'
    //             }
    //         },
    //         {
    //             path: 'yhtj',
    //             name: 'yonghutongji',
    //             hidden: true,
    //             component: () => import('@/views/TJFX/components/yhtjList.vue'),
    //             meta: {
    //                 title: '用户统计',
    //                 code: 'yhtj',
    //                 id: '00083'
    //             }
    //         }
    //     ]
    // },
    // {
    //     path: '/huiyigl',
    //     name: 'huiyigl',
    //     hidden: true,
    //     component: Layout,
    //     alwaysShow: true,
    //     redirect: '/huiyigl/huiyiguanli',
    //     meta: {
    //         title: '会议管理',
    //         icon: require('@/assets/icon/menu/meeting.png'),
    //         code: 'huiyigl',
    //         id: '0009'
    //     },
    //     children: [
    //         {
    //             path: 'huiyiguanli',
    //             name: 'huiyiguanli',
    //             hidden: true,
    //             component: () => import('@/views/HUIYI/huiyiIndex.vue'),
    //             meta: {
    //                 title: '会议列表',
    //                 code: 'yuiyilb',
    //                 id: '00091'
    //             }
    //         }
    //     ]
    // },
    // {
    //     path: '/jsgl',
    //     name: 'jsgl',
    //     hidden: true,
    //     component: Layout,
    //     alwaysShow: true,
    //     redirect: '/jsgl/jueseguanli',
    //     meta: {
    //         title: '角色管理',
    //         icon: require('@/assets/icon/menu/role.png'),
    //         code: 'jsgl',
    //         id: '0010'
    //     },
    //     children: [
    //         {
    //             path: 'jueseguanli',
    //             name: 'jueseguanli',
    //             hidden: true,
    //             component: () => import('@/views/JSGL/jsIndex.vue'),
    //             meta: {
    //                 title: '角色列表',
    //                 code: 'jslb',
    //                 id: '00101'
    //             }
    //         }
    //     ]
    // },
    // {
    //     path: '/yhgl',
    //     name: 'yhgl',
    //     hidden: true,
    //     component: Layout,
    //     alwaysShow: true,
    //     redirect: '/yhgl/yonghuguanli',
    //     meta: {
    //         title: '用户管理',
    //         icon: require('@/assets/icon/menu/user.png'),
    //         code: 'yhgl',
    //         id: '0011'
    //     },
    //     children: [
    //         {
    //             path: 'yonghuguanli',
    //             name: 'yonghuguanli',
    //             hidden: true,
    //             component: () => import('@/views/YHGL/yhIndex.vue'),
    //             meta: {
    //                 title: '用户列表',
    //                 code: 'yhlb',
    //                 id: '00111'
    //             }
    //         }
    //     ]
    // },
    // {
    //     path: '/bm',
    //     name: 'bm',
    //     hidden: true,
    //     component: Layout,
    //     alwaysShow: true,
    //     redirect: '/bm/bannermanage',
    //     meta: {
    //         title: 'Banner管理',
    //         icon: require('@/assets/icon/menu/banner.png'),
    //         code: 'bm',
    //         id: '0012'
    //     },
    //     children: [
    //         {
    //             path: 'bannermanage',
    //             name: 'bannermanage',
    //             hidden: true,
    //             component: () => import('@/views/BannerManage/bannerIndex.vue'),
    //             meta: {
    //                 title: 'Banner列表',
    //                 code: 'bannerlist',
    //                 id: '00121'
    //             }
    //         }
    //     ]
    // }
    { path: '*', redirect: '/', meta: { id : '0'} }
]
const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: constantRouterMap
})
// 全局拦截
router.beforeEach((to, from, next) => {
    console.log(to)
    const menuPower = sessionStorage.getItem('accountInfo') ? JSON.parse(sessionStorage.getItem('accountInfo')).menuCode : []
    if (sessionStorage.getItem('token') && to.fullPath !== '/login') {
        // if (to.matched.length > 0) {
        //     if (menuPower.every(n => n !== to.matched[0].meta.id)) {
        //         let fullPath = constantRouterMap.filter(item => item.meta.id === menuPower[0])[0].path
        //         next(fullPath)
        //     } else {
        //         next();
        //     }
        // } else {
        //     next();
        // }
        next();
    } else {
        if (to.fullPath == '/login') {
            next();
        } else {
            if (!to.query.code) {
                if(to.path == '/binddevice'){
                    if(to.query.appid){
                        let redirectUrl = encodeURIComponent(`https://nb-iot.zhdt-tech.com/binddevice?appid=${to.query.appid}`)
                        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${to.query.appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect` 
                    }else {
                        next('/login');   
                    }
                    // next();
                }else {
                    next('/login');  
                }   
            }else {
                if(to.path == '/binddevice'){
                    window.history.replaceState(null, null, window.location.href);
                     next();
                }else {
                    next('/login');   
                }
            }
            
        }
    }
})
const initWaterMark = () => {
    watermark.init({
        watermark_txt: `珠海鼎通科技有限公司`,
        watermark_x: -60,
        watermark_y: 0,
        watermark_x_space: 240,
        watermark_y_space: 240,
        watermark_color: '#2898ff',
        watermark_width: 178,
        watermark_angle: 325,
        watermark_alpha: 0.2,
        watermark_fontsize: '16px'
    })
}
router.afterEach((to, from) => {
    const noMarkPages = ['/login','/binddevice']
    if (!noMarkPages.includes(location.pathname)) {
        initWaterMark()
    }
})
export default router;
