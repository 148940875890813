import server from "./server";// 主 server
import loginServer from "@/views/Login/server"
import kcServer from "@/views/SBGL/Device/server"
import ddServer from "@/views/JKGL/server"
import rsServer from "@/views/SSGL/server"
import tjfxServer from "@/views/TJFX/server"
import bannerServer from "@/views/SBLXGL/server"
import jsServer from "@/views/JSGL/server"
import yhServer from "@/views/YHGL/server"
import pcServer from "@/views/PCGL/server"
// 合并所有serverApi
const serverApi = { ...server, ...loginServer, ...kcServer, ...ddServer, ...rsServer, ...tjfxServer, ...bannerServer,...jsServer, ...yhServer, ...pcServer };

const install = Vue => {
    if (install.installed) {
        return
    };
    install.installed = true;

    Object.defineProperties(Vue.prototype, {
        $api: {
            get() {
                return serverApi
            }
        }
    })
}

export default install;
