import $axios from "./service.util";


// 上传文件
const uploadFile = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/api/file/upload',
    method: 'post',
    data
  })
}

// 设置消息通知
const setNotice = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/middleService/wxMessage/bindUser',
    method: 'post',
    data
  })
}

export default {
  uploadFile,
  setNotice
}
