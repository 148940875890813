import $axios from "@/service/service.util.js";

// 获取订单列表
const getDDList = (data) => {
    return $axios({
        url: process.env.VUE_APP_BASE_API + '/api/order/list',
        method: 'post',
        data
    })
}

// 导出订单
const exportOrder = (data) => {
    return $axios({
        url: process.env.VUE_APP_BASE_API + '/api/order/export',
        method: 'post',
        responseType: "arraybuffer",
        data
    })
}

export default {
    getDDList,
    exportOrder
}
