import $axios from "@/service/service.util.js";

//管理员登录
const adminLogin = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/user/login',
    method: 'post',
    data
  })
}
export default {
  adminLogin
}
