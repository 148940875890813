<template>
    <div class="app-layout">
        <side-bar></side-bar>
        <div class="layout-right" :style="{'left':sidebar.width}">
            <nav-bar></nav-bar>
            <div class="main-container" :style="{height: Height + 'px'}">
                <app-main></app-main>
            </div>
        </div>
    </div>
</template>

<script>
import NavBar from "./components/NavBar/NavBar";
import SideBar from "./components/SideBar/SideBar";
import AppMain from "./components/AppMain/AppMain";
import { mapGetters } from 'vuex'

export default {
    components: {
        NavBar,
        SideBar,
        AppMain
    },
    data () {
        return {
            Height: null
        }
    },
    computed:{
        ...mapGetters(['sidebar'])
    },
    mounted(){
        // - 70 是 减去导航高度（写死的）
        this.Height = document.body.clientHeight - 80;
        window.onresize = () => {
            this.Height = document.body.clientHeight - 80;
        }
    }
}
</script>

<style>
    /* Layout.vue 样式 */
    @import url('./css/layout.css');
    /* Layout.vue End */
</style>
