import $axios from "@/service/service.util.js";

//课程销售统计
const courseOrderTJ = (data) => {
    return $axios({
        url: process.env.VUE_APP_BASE_API + '/api/statistical/courseOrder',
        method: 'post',
        data
    })
}
//课程销售统计导出
const exportCourseOrder = (data) => {
    return $axios({
        url: process.env.VUE_APP_BASE_API + '/api/statistical/courseOrder/export',
        method: 'post',
        responseType: "arraybuffer",
        data
    })
}

//讲师统计
const speakerTJ = (data) => {
    return $axios({
        url: process.env.VUE_APP_BASE_API + '/api/statistical/speaker',
        method: 'post',
        data
    })
}

//讲师统计导出
const exportSpeakerOrder = (data) => {
    return $axios({
        url: process.env.VUE_APP_BASE_API + '/api/statistical/speaker/export',
        method: 'post',
        responseType: "arraybuffer",
        data
    })
}

//播放统计
const playTJ = (data) => {
    return $axios({
        url: process.env.VUE_APP_BASE_API + '/api/statistical/courseStatistical',
        method: 'post',
        data
    })
}

//播放统计导出
const exportPlayData = (data) => {
    return $axios({
        url: process.env.VUE_APP_BASE_API + '/api/statistical/courseStatistical/export',
        method: 'post',
        responseType: "arraybuffer",
        data
    })
}
//用户统计
const userTJ = (data) => {
    return $axios({
        url: process.env.VUE_APP_BASE_API + '/api/statistical/userStatistical',
        method: 'post',
        data
    })
}

//用户统计导出
const exportUserData = (data) => {
    return $axios({
        url: process.env.VUE_APP_BASE_API + '/api/statistical/userStatistical/export',
        method: 'post',
        responseType: "arraybuffer",
        data
    })
}

export default {
    courseOrderTJ,
    exportCourseOrder,
    speakerTJ,
    exportSpeakerOrder,
    playTJ,
    exportPlayData,
    userTJ,
    exportUserData
}