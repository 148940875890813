import $axios from "@/service/service.util.js";

//获取设备类型
const getDeviceTypeList = (data) => {
    return $axios({
      url: process.env.VUE_APP_BASE_API + '/iot/equipment/list',
      method: 'post',
      data
    })
  }
  //新增设备类型
const addDeviceType = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/equipment/add',
    method: 'post',
    data
  })
}

 //修改设备类型
 const updateDeviceType = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/equipment/update',
    method: 'post',
    data
  })
}

//删除设备类型
const delDeviceType = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/equipment/logicDelete',
    method: 'post',
    data
  })
}


  export default {
    getDeviceTypeList,
    addDeviceType,
    updateDeviceType,
    delDeviceType
  }