import $axios from "@/service/service.util.js";

//获取设备全部批次
const getBatchList = (data) => {
    return $axios({
      url: process.env.VUE_APP_BASE_API + '/iot/deviceBatch/list',
      method: 'post',
      data
    })
  }
  //新增批次
const addBatch = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/deviceBatch/add',
    method: 'post',
    data
  })
}

 //修改批次
 const updateBatch = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/deviceBatch/update',
    method: 'post',
    data
  })
}

//删除批次
const delBatch = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/deviceBatch/logicDelete',
    method: 'post',
    data
  })
}

//设置水司
const setEnterpriseId = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/deviceBatch/setEnterpriseId',
    method: 'post',
    data
  })
}

//按批次同步至查表平台
const batchSynchronization = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/device/synchronization',
    method: 'post',
    data
  })
}

  export default {
    getBatchList,
    addBatch,
    updateBatch,
    delBatch,
    setEnterpriseId,
    batchSynchronization
  }