import $axios from "@/service/service.util.js";

//分页获取水司列表
const getEnprList = (data) => {
    return $axios({
      url: process.env.VUE_APP_BASE_API + '/iot/enterprise/list',
      method: 'post',
      data
    })
  }
  //新增水司
const addEnpr = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/enterprise/add',
    method: 'post',
    data
  })
}

 //修改水司信息
 const updateEnprInfo = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/enterprise/update',
    method: 'post',
    data
  })
}

//删除水司
const delEnprInfo = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/api/hotSearch/delete',
    method: 'post',
    data
  })
}

  export default {
    getEnprList,
    addEnpr,
    updateEnprInfo
  }