import $axios from "@/service/service.util.js";

// 获取设备列表
const getDecieList = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/device/list',
    method: 'post',
    data
  })
}
// 获取JWX设备列表
const getJWXDecieList = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/device/jwx/list',
    method: 'post',
    data
  })
}
//修改水司
const modifyEnpr = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/device/updateEnterpriseId',
    method: 'post',
    data
  })
}
//获取所有水司
const getAllEnpr = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/enterprise/allList',
    method: 'post',
    data
  })
}
//更新设备所属水司
const updateEnpr = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/device/updateEnterpriseId',
    method: 'post',
    data
  })
}
// 删除设备
const delDevice = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/device/logicDelete',
    method: 'post',
    data
  })
}
//下发阀控指令(移动)
const releaseOrder = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/cmDevice/releaseOrder',
    method: 'post',
    data
  })
}

//下发阀控指令(电信)
const releaseOrder2 = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/ctDevice/releaseOrder',
    method: 'post',
    data
  })
}

//注册设备
const registration = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/device/registration',
    method: 'post',
    data
  })
}

//获取所有批次
const getAllBatchs = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/deviceBatch/allList',
    method: 'post',
    data
  })
}

//获取所设备类型
const getAllDeviceType = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/equipment/allList',
    method: 'post',
    data
  })
}

//单表同步至抄表平台
const synchronizationOne = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/device/synchronizationOne',
    method: 'post',
    data
  })
}

//获取抄表记录
const deviceHistoryList = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/device/deviceHistoryList',
    method: 'post',
    data
  })
}

//获取抄表记录JWX
const deviceJWXHistoryList = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/device/jwx/deviceHistoryList',
    method: 'post',
    data
  })
}

//获取集中器列表
const getJZQList = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/concentrator/pageList',
    method: 'post',
    data
  })
}

//获取集中器列表
const addJZQDevice = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/concentrator/create',
    method: 'post',
    data
  })
}

//获取集中器设备列表
const addJZQChildList = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/concentrator/devicePageList',
    method: 'post',
    data
  })
}

//删除集中器
const deleteJZQ = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/concentrator/delete',
    method: 'post',
    data
  })
}

//获取集中器表历史记录
const getJZQHistoryList = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/concentrator/deviceHistoryList',
    method: 'post',
    data
  })
}

//获取集中器设备详情
const getJZQDeviceList = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/concentrator/deviceList',
    method: 'post',
    data
  })
}

//绑定集中器设备
const relationDevice = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/concentrator/relationDevice',
    method: 'post',
    data
  })
}

//adk阀控
const controlADKValve = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/adk/valveControl',
    method: 'post',
    data
  })
}

//设置底数
const setValue = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/device/updateReadValue',
    method: 'post',
    data
  })
}

//jwx强制阀控
const jwxValveControl= (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/jwx/valveControl',
    method: 'post',
    data
  })
}

export default {
  getDecieList,
  getJWXDecieList,
  modifyEnpr,
  getAllEnpr,
  updateEnpr,
  delDevice,
  releaseOrder,
  releaseOrder2,
  registration,
  getAllBatchs,
  getAllDeviceType,
  synchronizationOne,
  deviceHistoryList,
  deviceJWXHistoryList,
  getJZQList,
  addJZQDevice,
  addJZQChildList,
  deleteJZQ,
  getJZQHistoryList,
  getJZQDeviceList,
  relationDevice,
  controlADKValve,
  setValue,
  jwxValveControl
}
