import $axios from "@/service/service.util.js";

//获取角色列表
const getJSList = (data) => {
    return $axios({
      url: process.env.VUE_APP_BASE_API + '/api/permission/role/list',
      method: 'post',
      data
    })
  }
  //保存角色
const saveJS = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/api/permission/role/save',
    method: 'post',
    data
  })
}

 //修改角色
 const updateJS = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/api/permission/role/update',
    method: 'post',
    data
  })
}

//删除角色
const delJS = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/api/permission/role/delete',
    method: 'post',
    data
  })
}

//获取菜单Menu
const getJSMenu = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/api/permission/menu/list',
    method: 'post',
    data
  })
}

  export default {
    getJSList,
    saveJS,
    updateJS,
    delJS,
    getJSMenu
  }